<template>
    <div class="page bg-white" id="reorganize">
        <el-row :gutter="20">
            <el-col :span="6">
                <div class="lump">
                    <div class="catalogueBox rulesBox">
                        <el-table v-loading="loading2"
                                  :data="catalogueList"
                                  ref="catalogueTable"
                                  size="small"
                                  height="calc(100vh - 228px)"
                                  class="table" highlight-current-row
                                  @current-change="handleCurrentChange">
                            <el-table-column prop="templateName" label="档号规则">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.id!=='' && !scope.row.showIpt">{{scope.row.templateName}}</div>
                                    <div v-if="scope.row.id!=='' && scope.row.showIpt">
                                        <el-input
                                                class="input-new-tag"
                                                v-model="scope.row.templateName"
                                                ref="saveTagInput2"
                                                size="small"
                                                maxlength="50"
                                                @keyup.enter.native="edit(scope.row)"
                                                @blur="inputBlur"
                                                ></el-input>
                                    </div>
                                    <div v-if="scope.row.id===''">
                                        <el-input
                                                class="input-new-tag"
                                                v-if="inputVisible"
                                                v-model="inputValue"
                                                ref="saveTagInput"
                                                size="small"
                                                maxlength="50"
                                                @keyup.enter.native="handleInputConfirm"
                                                @blur="inputBlur"
                                                autofocus
                                                ></el-input>
                                        <el-button v-else icon="el-icon-plus" type="primary" plain size="small"
                                                   @click="showInput"></el-button>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="100">
                                <template slot-scope="scope" v-if="scope.row.id!=''">
                                    <el-button size="mini" type="primary" plain icon="el-icon-edit" @click="scope.row.showIpt=!scope.row.showIpt"></el-button>
                                    <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="delTemplate(scope.row.id)"></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="pageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize"
                                :total="total"
                                background
                                layout="prev, pager, next">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <div class="lump">
                    <el-row class="headBox">
                        <el-col :span="20">
                            <span style="font-weight: 700">档号规则：</span>{{title}}
                        </el-col>
                        <el-col :span="4" class="text_right">
                            <el-button icon="el-icon-plus" size="small" type="primary" :disabled="exhibitionId==''" @click="view('add', exhibitionId)">新建</el-button>
                        </el-col>
                    </el-row>
                    <div class="catalogueBox">
                        <el-table
                                :data="fileList"
                                size="small"
                                @selection-change="selectionChangeHandle"
                                v-loading="loading"
                                height="calc(100vh - 238px)"
                                class="table">
                            <el-table-column type="selection" width="50"/>
                            <el-table-column prop="porder" label="排序" width="50"></el-table-column>
                            <el-table-column prop="recordName" label="著录项名" show-overflow-tooltip/>
                            <el-table-column prop="frontConnector" label="前连接符" show-overflow-tooltip/>
                            <el-table-column fixed="right" label="操作" width="120">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" plain @click="view('edit', scope.row)">编辑
                                    </el-button>
                                    <el-button size="mini" type="danger" plain @click="del( scope.row.id)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-col>
        </el-row>
        <AddForm ref="addForm" @refreshDataList="getFileList"></AddForm>
    </div>
</template>

<script>
    import AddForm from './addForm' // 创建模板弹窗

    export default {
        components: {AddForm},
        data() {
            return {
                visibleLog: false,
                catalogueList: [],
                loading2:false,
                inputVisible: false,
                inputValue: '',
                pageNo: 1,
                pageSize: 10,
                total: 0,
                fileList: [],
                dataListSelections: [],
                loading: false,
                exhibitionId: "",
                title:""
            }
        },
        mounted() {
            this.getCatalogue();
        },
        methods: {
            inputBlur(){
                this.inputVisible = false;
            },
            showInput() {
                this.inputVisible = true;
            },
            //编辑模板
            edit(val){
                if(val.templateName){
                    this.$nextTick(_ => {
                        this.$refs.saveTagInput2.focus();
                    })
                    this.loading2 = true
                    let inputForm = {
                        id:val.id,
                        templateName: val.templateName,
                        templateType:1
                    }
                    this.$axios(this.api.zlzs.catalogTemplateUpdateById, inputForm, 'put').then(data => {
                        this.loading2 = false
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.getCatalogue();
                        }
                    })
                }
                this.getCatalogue();
                val.showIpt = false;
            },
            //新增模板
            handleInputConfirm() {
                let inputValue = this.inputValue.trim();
                if (inputValue) {
                    this.loading2 = true
                    let inputForm = {
                        templateName: inputValue,
                        templateType:1
                    }
                    this.$axios(this.api.zlzs.catalogTemplateSave, inputForm, 'post').then(data => {
                        this.loading2 = false
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.getCatalogue();
                        }
                    })
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            // 新建、编辑
            view(method, row) {
                this.$refs.addForm.init(method, row)
            },
            // 表格多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 查询模板
            getCatalogue() {
                this.loading2 = true
                this.$axios(this.api.zlzs.catalogTemplateList, {
                    'templateName': this.catalogueSrh,
                    "templateType":1,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        let that = this;
                        this.catalogueList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading2 = false
                        this.catalogueList.push({id:""})
                        this.catalogueList.forEach(function (v) {
                            that.$set(v, 'showIpt', false)
                        })
                    }
                })
            },
            // 点击展览行
            handleCurrentChange(val) {
                if(val && val.id){
                    this.$refs.catalogueTable.setCurrentRow(val);
                    this.exhibitionId = val.id
                    this.getFileList()
                }
            },
            // 查询目录
            getFileList() {
                if(this.exhibitionId !==''){
                    this.loading = true
                    this.title = ""
                    this.$axios(this.api.zlzs.rulesList, {
                        'rulesName': this.exhibitionId,
                    }, 'get').then(data => {
                        if (data && data.status) {
                            let that = this;
                            this.fileList = data.data
                            this.loading = false
                            if(this.fileList.length>0){
                                this.fileList.forEach(function (v) {
                                    let j = JSON.parse(JSON.stringify(v))
                                    that.title+=j.frontConnector+=j.recordName
                                })
                            }
                        }
                    })
                }
            },
            delTemplate(id){
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.catalogTemplateRemoveById, {
                        'id': id,
                    }, 'delete').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.getCatalogue()
                            this.exhibitionId = "-1"
                            this.getFileList()
                            
                        }else{
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 删除、批量删除
            del(id) {
                // 批量删除多个id用逗号隔开
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.rulesRemoveById, {
                        'id': ids,
                    }, 'delete').then(data => {
                        if(data && data.status){
                            this.$message.success(data.msg)
                            this.getFileList()
                        }
                    })
                })
            },
            // 展览每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getCatalogue();
            },
            // 展览当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getCatalogue();
            },
        }
    }
</script>

<style scoped>

</style>
